@import "./scss/index.scss";
/* *************** MENU ************************* */

.SystemPages .content-wrapper.twoColumns {
  display: table;
  width: 100%;
  height: 100%;
}

.SystemPages .content-wrapper.twoColumns .site-menu {
  /* MENU */
  display: table-cell;
  position: relative;
  width: 329px;
  min-width: 329px;
}

.SystemPages .content-wrapper.twoColumns .mainTable-wrapper {
  /* CONTENT */
  display: table-cell;
  width: 100%;
  position: relative;
  background: #f5f5f5;
  padding: 0 0 20px 0;
}

.SystemPages .content-wrapper.twoColumns .site-menu .credit {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.SystemPages .mainMenuTable {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: white;

  border-left: 1px solid #d7d7d7;
}

.SystemPages .mainMenuTable header a.logOut {
  display: block;
  text-align: left;
  margin: -15px 0 10px 0;
  color: black;
  text-decoration: none;
  font-size: 16px;
}

.SystemPages .mainMenuTable header a.logOut span {
  margin: 0 5px 0 0;
}

.SystemPages .mainMenuTable header a.logOut:hover {
  color: $primary-color;
  text-decoration: underline;
}

.SystemPages .content-wrapper.twoColumns .site-menu header {
  text-align: right;
  padding: 30px 0 30px;
  width: 85%;
  margin: 0 auto;
}

.SystemPages .content-wrapper.twoColumns .site-menu header img.logoClient {
  display: block;
  width: 180px;
  margin: 20px 0 0 0;
}

.SystemPages .content-wrapper.twoColumns .site-menu .mainMenuNav {
  width: 100%;
  margin: 0 0 0 0;
}

.SystemPages .content-wrapper.twoColumns .site-menu .mainMenuNav a,
.SystemPages nav.mobileNavbar .navbar-collapse .navbar-nav a {
  width: 100%;
  display: block;
  /* border-bottom: 1px solid #193960; */
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  min-height: 52px;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/* .SystemPages .content-wrapper.twoColumns .site-menu .mainMenuNav a:first-child,
.SystemPages nav.mobileNavbar .navbar-collapse .navbar-nav a:first-child {
    border-top: 1px solid #193960;
    bo
} */

.SystemPages .content-wrapper.twoColumns .site-menu .mainMenuNav a.is-active,
.SystemPages .content-wrapper.twoColumns .site-menu .mainMenuNav a:hover,
.SystemPages .content-wrapper.twoColumns .site-menu .mainMenuNav a:focus,
.SystemPages nav.mobileNavbar .navbar-collapse .navbar-nav a.is-active,
.SystemPages nav.mobileNavbar .navbar-collapse .navbar-nav a:hover,
.SystemPages nav.mobileNavbar .navbar-collapse .navbar-nav a:focus {
  /* font-family: "fb_bold","helvetica","arial"; */
  background: #e4e7ef;
  border-bottom: none;
}

.SystemPages
  .content-wrapper.twoColumns
  .site-menu
  .mainMenuNav
  a
  img.bulletMainMenu,
.SystemPages
  nav.mobileNavbar
  .navbar-collapse
  .navbar-nav
  a
  img.bulletMainMenu {
  margin: 0 0 0 20px;
}

.SystemPages .site-menu .fixedPosition {
  position: fixed;
  width: 329px;
  top: 0;
  bottom: 0;
  height: 100%;
  background: white;
  -webkit-box-shadow: -1px 0px 5px 1px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: -1px 0px 5px 1px rgba(0, 0, 0, 0.29);
  box-shadow: -1px 0px 5px 1px rgba(0, 0, 0, 0.29);
}

.SystemPages nav.mobileNavbar {
  background: #2f4155 !important;
}

.SystemPages nav.mobileNavbar .navbar-collapse .navbar-nav {
  padding: 20px 0 10px 5%;
}

.SystemPages nav.mobileNavbar .navbar-brand {
  margin-left: 10px;
}

.SystemPages .NavInner {
  padding: 20px 2% 10px;
  background: white;
  position: relative;
  z-index: 10;

  -webkit-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.2);
}

.SystemPages .mainContent {
  padding: 20px 1.5% 10px;
  position: relative;
  min-width: 1100px;
}

.SystemPages .NavInner .searchNavInner input {
  background: #e9e9e9;

  border-radius: 0 30px 30px 0 !important;
  -moz-border-radius: 0 30px 30px 0 !important;
  -webkit-border-radius: 0 30px 30px 0 !important;
  padding-right: 20px;
  border-left: none;
}

.SystemPages .NavInner .searchNavInner button {
  border-radius: 30px 0 0 30px !important;
  -moz-border-radius: 30px 0 0 30px !important;
  -webkit-border-radius: 30px 0 0 30px !important;

  background: #e9e9e9;
  border: 1px solid #ced4da;
  border-right: none;
}

.SystemPages .NavInner .searchNavInner button:hover,
.SystemPages .NavInner .searchNavInner button:focus {
  color: #359ee5;
}

.SystemPages .NavInner .userLogout .userCircle {
  color: #2f4155;
  font-size: 40px;
  display: inline-block;
  vertical-align: middle;
}

.SystemPages .NavInner .userCont {
  min-width: 100%;
  text-align: left;
}
.SystemPages .NavInner .userLogout .userCont .logOutBtn {
  padding: 0;
  /* display: inline-block; */
  vertical-align: middle;
  color: #2f4155;
  background: none;
  border: none;
  font-size: 20px;
  margin: 5px 20px 0 0;
  float: left;
}

.SystemPages .NavInner .userLogout .userCont .logOutBtn:hover,
.SystemPages .NavInner .userLogout .userCont .logOutBtn:focus {
  color: #359ee5;
}

.BulletMenuCont {
  display: inline-block;
}

.BulletMenuCont .bCountCont {
  position: relative;
}

.BulletMenuCont .bCountCont .number {
  position: absolute;
  background: #f5325c;
  border: 2px solid #fbadbe;
  border-radius: 50%;
  font-size: 11px;
  height: 24px;
  text-align: center;
  width: 24px;
  padding: 2px 0 0 0;
  top: -5px;
  right: -16px;
}

.BulletMenuCont .bCountCont img {
  width: 30px;
}

/* *************** END MENU ************************* */

.site-menu .userData {
  text-align: right;
  padding: 0 10% 0 0;
  margin: 0 0px 30px 0;
}

.site-menu .userData .circleGrey {
  text-align: center;
  display: inline-block;
  background: #bcacc2;
  width: 44px;
  height: 44px;
  font-size: 35px;
  line-height: 1;
  color: white;
  border-radius: 50%;
  padding: 2px 0 0 0;
  margin: 0 0 0 10px;
}

.site-menu .userData span {
  font-family: $fontLight;
  font-size: 20px;
}

.smallWrapper {
  width: 1000px;
  margin: 30px auto 0;
}

.smallWrapper.noTopMarg {
  margin-top: 0;
}

.BtnSelectCont .customBtnSelect {
  background: white;
  border: none;
  padding: 5px 15px;
  min-width: 130px;
  border: 1px solid #dee5ec;
  border-left: none;
  font-size: 15px;
}

.BtnSelectCont .customBtnSelect:first-child {
  border-radius: 0 5px 5px 0;
}

.BtnSelectCont .customBtnSelect:last-child {
  border-left: 1px solid #dee5ec;
  border-radius: 5px 0 0 5px;
}

.BtnSelectCont .customBtnSelect small {
  font-size: 13px;
}

.BtnSelectCont .customBtnSelect.active {
  border: 1px solid $primary-color;
  background: $primary-color;
  color: white;
}

.BtnSelectCont .customBtnSelect:hover {
  background: $primary-color-hover;
  color: white;
  border: 1px solid $primary-color-hover;
}

/* ------ GENERAL ----------- */

/* .customInput {

} */

.customInput.searchIco {
  background: url("../img/icons/SVG/search.svg") right 13px center white
    no-repeat;
  background-size: 20px;
  margin: 0 0 10px 0;
  padding-right: 40px;
}

.customSelectInput {
  background: url("../img/icons/SVG/downSelect.svg") left 16px center white
    no-repeat;
  background-size: 12px;
  margin: 0 0 10px 0;
  padding: 18px 10px 8px 37px !important;
}

.customSelect { //no floatLebel
  background: url("../img/icons/SVG/downSelect.svg") left 16px center white
    no-repeat;
  background-size: 12px;
  margin: 0 0 10px 0;
  
}

.customSelectInput.noLabel {
  padding: 6px 10px 6px 20px !important;
}

.input.inputTypesearchDB {
  position: relative;
  z-index: 10;
  background: white;
}

.customInputSearch .jZkBQk > .wrapper {
  position: relative;
  background: white;
  margin: 5px 0 0 0;
  border-radius: 0;
  border: none;
}

.customInputSearch .jZkBQk > .wrapper .sc-bwzfXH {
  position: relative;
  z-index: 10;
  box-shadow: rgb(32 33 36 / 28%) 0px 1px 6px 0px;
}

.customInputSearch .jZkBQk > .wrapper .sc-bwzfXH ul {
  margin: -60px 0 0 0;
}

.customInputSearch .jZkBQk > .wrapper .sc-bxivhb {
  padding: 0 0 5px 0;
  border-bottom: 1px solid #d4d7e6;
}

.customInputSearch .jZkBQk > .wrapper:hover,
.customInputSearch .jZkBQk > .wrapper:focus {
  box-shadow: none;
}

.customInputSearch.errorClass .jZkBQk > .wrapper .sc-bxivhb {
  border-color: #dc3545;
}

.input .invalid-feedback {
  font-size: 14px;
}

.input .form-floating > .form-control {
  /* padding: 13px 10px 0 0; */
  border: none;
  border-radius: 10px;
  border: 1px solid #dee5ec;
  height: calc(3rem + 2px);
}

.input .form-floating > .form-control.numberClass {
  font-size: 16px;
}

.input.inputTypedate .form-floating > .form-control {
  padding: 0 0 0px 0;
}

.input.inputTypedate .form-floating > .form-control:focus ~ label,
.input.inputTypedate
  .form-floating
  > .form-control:not(:placeholder-shown)
  ~ label,
.input.inputTypedate .form-floating > .form-select ~ label {
  margin-top: 1px;
  padding: 0;
  font-size: 17px;
  /* opacity: 1; */
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  /* background: url('../img/icons/SVG/downSelect.svg') left 16px center white no-repeat !important;  */
  background-position: left 10px center;
  color: #dc3545;
  padding: 0.375rem 30px;
}

.input .form-floating > label {
  padding: 13px 13px 0 13px;
  margin-right: 0;
}

.inputs .rows2 .input {
  width: 49%;
}

.inputs .smallInputs .input {
  width: 20%;
  display: inline-block;
  vertical-align: top;
  margin-left: 2%;
}

.inputs .smallInputs .input:last-child {
  margin-left: 0;
}

.inputs .rows2 .input:nth-child(1) {
  float: right;
}
.inputs .rows2 .input:nth-child(2) {
  float: left;
}

.selectButtons label {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  margin: 0 0 0 30px;
}

.selectButtons label.errorClass {
  color: #dc3545;
}

.selectButtons .buttonsCont {
  display: inline-block;
  vertical-align: middle;
}

.selectButtons .buttonsCont button {
  border: 1px solid #d4d7e6;
  background: none;
  padding: 10px 10px;
  margin: 0 0 0 20px;
  font-size: 20px;
  min-width: 150px;
  text-align: right;
  border-radius: 10px;
}

.selectButtons .buttonsCont button:hover,
.selectButtons .buttonsCont button.selected {
  background-color: #f1f3fc;
  border-color: #1991d0;
}

.selectButtons .buttonsCont button img {
  width: 20px;
  margin: 0 0 0 15px;
}

.checkboxes .checkBoxCont label {
  margin: 0 10px 0 0;
}

.checkboxes .checkBoxCont .form-check-input {
  width: 13px;
  height: 13px;
  vertical-align: top;
  margin-top: 6px;
}
.checkboxes .checkBoxCont .form-check-input:checked {
  background-color: $primary-color;
  border-color: $primary-color;
}

.checkboxes .checkBoxCont label {
  font-size: 16px;
  color: $blackfontText;
  vertical-align: top;
  display: inline-block;
  width: 70%;
}

.sendBlueBtn {
  background: $primary-color;
  border-color: $primary-color;
  padding: 10px 60px;
  color: white;
  display: inline-block;
}

.sendBlueBtn.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.sendBlueBtn:hover,
.sendBlueBtn:focus,
.sendBlueBtn:active {
  background: $primary-color-hover;
  border-color: $primary-color-hover;
}

.sendBlueBtn.withDisabled.btn-disabled,
.sendBlueBtn:disabled {
  background: grey;
  border-color: grey;
}

.sendBlueBtn.withDisabled.btn-disabled:hover,
.sendBlueBtn.withDisabled.btn-disabled:focus {
  color: white;
}

.singIn {
  padding: 0;
  text-align: left;
  margin: 0 0 30px 0;
}

.singIn a {
  color: $blackfontText;
  text-decoration: none;
  font-size: 16px;
}

.singIn a strong {
  font-weight: normal;
  color: #0058ff;
}

.singIn a:hover strong,
.singIn a:focus strong {
  text-decoration: underline;
}

.inputsWithLabel label {
  font-family: $fontBold;
  font-size: 15px;
}

.inputsWithLabel input,
.inputsWithLabel select {
  padding: 10px 50px 10px 10px;
  border-color: #e2e5ef;
  font-size: 17px;
}

.inputsWithLabel {
  position: relative;
}

.selectIcoBG {
  background-image: url("../img/icons/SVG/downSelect.svg");
  background-position: 10px center;
  background-repeat: no-repeat;
  background-size: 10px;
}

.multiSelect.rmsc .dropdown-heading-value span {
  color: $blackfontText;
}

.multiSelect .dropdown-content ul li {
  direction: ltr;
  text-align: right;
  padding: 0;
  margin: 0;
}

.multiSelect.rmsc {
  border-radius: 5px;
}

.multiSelect.rmsc .dropdown-container {
  border: 1px solid #e2e5ef;
  border-radius: 5px;
  direction: rtl;
}

/* .multiSelect.rmsc .dropdown-container ul li input  {
    
    display: none;
    
} */

.multiSelect.rmsc .dropdown-container ul li span {
  text-align: right;
  display: block;
  width: 100%;
}

.multiSelect.rmsc .dropdown-container .item-renderer {
  direction: rtl;
}

.multiSelect.rmsc .dropdown-container .item-renderer input {
  width: 20px;
  margin: 0 0 0 10px;
}

.multiSelect.rmsc .dropdown-container ul li {
  text-align: right;
  width: 100%;
}

.multiSelect.rmsc .dropdown-heading {
  padding-right: 50px;
  background: url("../img/sherut-leumi/svg/sherutPlaces/3.svg") right 13px
    center white no-repeat;
  background-size: 20px;

  font-size: 17px;
  height: 45px;
}

.multiSelect.disabled .dropdown-heading {
  background-color: #e9ecef;
}

/* .multiSelect  */

.noResults {
  margin: 20px 0 0 0;
  color: red;
  font-size: 25px;
}

h1.titleStyle {
  font-size: 35px;
  font-family: $fontLight;
}

h1.titleStyle::after {
  content: "";
  width: 41px;
  height: 3px;
  display: block;
  background: #8d41ab;
}

.defaultTable {
  /* display: table; */
  width: 100%;
}

.defaultTable .tableTR {
  /* display: table-row; */
  margin: 0 0 15px 0;
  border-radius: 5px;
  background: white;
  padding: 20px 0px 18px;
  line-height: 1;
  box-shadow: 0px 2px 5px 1px rgb(0 0 0 / 17%);
  cursor: pointer;
}

.defaultTable .tableTR .tableTD {
  /* display: table-cell; */
  padding: 0 5px;
  text-align: center;
  /* border-bottom: 10px solid #eef0f8; */
  display: inline-block;
  font-size: 15px;
  line-height: 1;
  vertical-align: middle;
}

.defaultTable .tableTR.titleRow {
  box-shadow: none;
}

.defaultTable .tableTR.titleRow .tableTD button {
  background: none;
  border: none;
  font-family: $fontBold;
  font-size: 15px;
  color: $blackfontText;
  margin: 0;
  padding: 0;
}

.defaultTable .tableTR.titleRow .tableTD button:hover,
.defaultTable .tableTR.titleRow .tableTD button:focus {
  color: #0058ff;
}

.defaultTable .tableTR.titleRow .tableTD button i {
  font-size: 15px;
}

.defaultTable .tableTR .tableTD:nth-child(1) {width: 7%;} /* id */
.defaultTable .tableTR .tableTD:nth-child(2) {width: 12%;} /* date */
.defaultTable .tableTR .tableTD:nth-child(3) {width: 13%;} /* hospital */
.defaultTable .tableTR .tableTD:nth-child(4) {width: 13%;} /* hospitalDivision */
.defaultTable .tableTR .tableTD:nth-child(5) {width: 13%;} /* hospitalshift  */
.defaultTable .tableTR .tableTD:nth-child(6) {width: 13%;} /* patient */
.defaultTable .tableTR .tableTD:nth-child(7) {width: 13%;} /* patientPhone */
.defaultTable .tableTR .tableTD:nth-child(8) {width: 14%; padding: 0;} /* orderStatus */

.defaultTable .tableTR .tableTD .editBtn {
  background: none;
  border: none;
  color: $blackfontText;
  margin: -1px 0 0 0;
  padding: 0;
  float: left;
}

.defaultTable .tableTR .tableTD span {
  vertical-align: middle;
}

.defaultTable .tableTR .tableTD .editBtn.selectPic img {
  width: 18px;
}

.defaultTable .tableTR:hover .tableTD .editBtn img {
  margin: -2px 0 0 0;
  width: 22px;
}

.defaultTable .tableTR:hover {
  background: #d9e4f1;
  box-shadow: 0px 5px 5px 1px rgb(0 0 0 / 17%);
}

.defaultTable .tableTR.alert {
  background: #f6c5c5;
}

.defaultTable .tableTR.titleRow:hover {
  background: white;
}

.showStatus {
  background: rgba(0, 0, 0, 0.29);
  display: inline-block;
  vertical-align: middle;
  padding: 5px 17px 5px 10px;
  line-height: 1;
  border-radius: 30px;
  margin: 0 0 0 6px;
  font-size: 15px;
}

.showStatus::before {
  content: "";
  width: 5px;
  height: 5px;
  background: $blackfontText;
  position: absolute;
  border-radius: 50%;
  margin: 5px -9px 0 0;
}

.showStatus.green {
  background: #ddfaf3;
  color: #1caa84;
}
.showStatus.green::before {
  background: #1caa84;
}

.showStatus.orange {
  background: #f9e9cb;
  color: grey;
}

.showStatus.orange::before {
  background: grey;
}

.showStatus.red {
  background: #d49c9c;
  color: #be1919;
}
.showStatus.red::before {
  background: #be1919;
}

.showStatus.yellow {
  background: #f9fadd;
  color: #f4bd0e;
}
.showStatus.yellow::before {
  background: #f4bd0e;
}

.defaultTable .showStatus.padd {
  margin-top: 5px;
}

.showStatus.cyan {
  background: #b5e6fc;
  color: #0997af;
}
.showStatus.cyan::before {
  background: #0997af;
}

.showStatus.grey {
  background: #e0e7eb;
  color: $blackfontText;
}
.showStatus.grey::before {
  background: black;
}

.showPriority {
  background: transparent;

  border: 1px solid grey;

  display: inline-block;
  vertical-align: middle;
  padding: 5px 25px;

  line-height: 1;
  border-radius: 5px;
  margin: 0 0 0 6px;
  font-size: 15px;
}

.showPriority.colorId1 {
  border-color: #ff001a;
  color: #ff001a;
}

.showPriority.colorId2 {
  border-color: #ff7700;
  color: #ff7700;
}

.showPriority.colorId3 {
  border-color: #209f84;
  color: #209f84;
}

.defaultBtnPic {
  border: none;
  background: $primary-color;
  border-radius: 7px;
  color: white;
  padding: 5px 20px;
  box-shadow: 0px 2px 5px 1px rgb(0 0 0 / 17%);
  display: inline-block;
  text-decoration: none;
}

.defaultBtnPic.disabledBtn {
  opacity: 0.5;
}

.defaultBtnPic.noPic {
  padding: 5px 60px;
}

.defaultBtnPic.noPic.noPadd {
  padding: 5px 20px;
}

.defaultBtnPic.noPic.greyBack {
  background: #d8dfe6;
  padding: 5px 20px;
  margin: 0 0 0 10px;
  color: $blackfontText;
}

.defaultBtnPic.noPic.greyBack:hover,
.defaultBtnPic.noPic.greyBack:focus {
  background: grey;
  color: white;
}

.defaultBtnPic.noPic.cancelOrderBtn {

  background: red;
  float: left;
  margin: -60px 0 0 0;

  &:hover {

    background: $primary-color;

  }

  &.grey {
    background: grey;
    color: rgb(165, 165, 165);
  }

}

.defaultBtnPic span {
  font-size: 18px;
  font-weight: normal;
  font-family: $fontRegular;
}

.defaultBtnPic img {
  margin: 0 0 0 10px;
}

.defaultBtnPic:hover,
.defaultBtnPic:focus {
  background: $primary-color-hover;
  color: white;
}

.defaultBtnPic.grey {
  background: #d8dfe6;
  color: $blackfontText;
}
.defaultBtnPic.grey:hover,
.defaultBtnPic.grey:focus {
  background: white;
}

.defaultBtnPic.yellow {
  background: $seccond-color;
}

.defaultBtnPic.red {
  background: $redDanger;
}

.defaultBtnPic.yellow:hover,
.defaultBtnPic.yellow:focus {
  background: $primary-color;
}

.reloadBtn {
  border: none;
  background: none;
  color: $blackfontText;
  padding: 0;
}

.reloadBtn:hover,
.reloadBtn:focus {
  color: #0058ff;
}


.defaulAcordPading {
  padding: 20px 1.25rem;
}

/* ------ END GENERAL ----------- */

.buttonsOrder {
  margin: 0 0 30px 0;
}

.buttonsOrder button,
.buttonsOrder a {
  margin: 0 0 0 20px;
}

.tableLoginRegister {
  position: relative;
  z-index: 2;
  display: table;
  width: 100%;
  min-height: 850px;
}

.tableLoginRegister .ColLogin {
  display: table-cell;
  width: 50%;
  vertical-align: middle;
}

.tableLoginRegister .ColLogin.LColLogin {
  background: white;
}

.tableLoginRegister .picRight {
  padding: 0 2% 0;
  background: #eef0f8;
}

/*  .PagesLogin.Desktop {

    background: right 20px no-repeat transparent;
    padding: 0 0 20px 0;
    min-height: 1012px;
} */

.PagesLogin .whiteBG {
  background: linear-gradient(90deg, white 50%, #eef0f8 50%);
  /* background: white; */
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.logoDown {
  position: fixed;
  bottom: 0;
  width: 50%;
  left: 0;
  z-index: 2;
  text-align: center;
  padding: 0 0 10px 0;
}

.logoDown img {
  width: 150px;
}

.PagesLogin .firstRegister {
  margin: 0 auto;
  width: 320px;
  padding: 0;
}

.PagesLogin .firstRegister header {
  text-align: right;
  margin: 0 0 20px 0;
}

.PagesLogin .firstRegister header h1 {
  font-size: 30px;
  margin: 40px 0 0px 0;
  letter-spacing: -1px;
}

.PagesLogin .firstRegister header p {
  font-size: 17px;
}

.PagesLogin .firstRegister header img {
  width: 200px;
}

.firstRegister .inputs {
  width: 100%;
  margin: 0 auto;
}

.firstRegister.loginPage .inputs {
  width: 310px;
}

.firstRegister .inputs .rows3 .input {
  float: right;
  width: 46%;
}

.firstRegister .inputs .rows3.inputs3 .input {
  width: 32%;
  margin: 0 0 0 1.5%;
}

.firstRegister .inputs .rows3.inputs3 .input:last-child {
  margin-left: 0;
  float: left;
}

.firstRegister .inputs .rows3 .phoneInputs {
  float: left;
  width: 46%;
  direction: ltr;
}

.firstRegister .inputs .rows3 .phoneInputs .input {
  float: left;
  text-align: right;
  direction: rtl;
}

.firstRegister .inputs .rows3 .phoneInputs .input:nth-child(2) {
  float: right;
  width: 53%;
}

.firstRegister .inputs .rows3 .phoneInputs .customSelectInput {
  width: 70px;
  float: left;
  padding-right: 0;
}

.firstRegister .line {
  margin: 0 0 0px 0;
  min-height: 76px;
}

.firstRegister .inputs.Sherut .input.fullW .input {
  width: 100%;
}

.phoneInputs .input .form-floating > label {
  font-size: 16px;
  /* padding: 20px 0 0 0; */
}

.phoneInputs .form-floating > .form-control:focus ~ label,
.phoneInputs .form-floating > .form-control:not(:placeholder-shown) ~ label,
.phoneInputs .form-floating > .form-select ~ label {
  font-size: 12px;
  padding: 13px 0 0 0;
}

.phoneInputs .input .form-floating > .form-control {
  padding: 5px 0 0 0;
  font-size: 16px;
}

.phoneInputs .inputTypeselect .invalid-feedback {
  float: left;
  padding: 0 25px 0 0;
}

.PagesLogin .firstRegister .line.selectButtons {
  margin: 15px 0 0 0;
}

.PagesLogin .firstRegister .line.checkboxes {
  margin: 10px 0 20px 0;
}
.PagesLogin .firstRegister .line.checkboxes .checkBoxCont {
  margin: 0px 0 5px 0;
}

.firstRegister.loginPage {
  margin-top: 4%;
}

.firstRegister.loginPage .inputs .singIn {
  padding: 30px 0;
  margin: 20px 0 0 0;
}

.firstRegister.loginPage .inputs .singIn.reSendPass {
  padding: 0;
  margin: 0 0 50px 0;
  text-align: left;
}

.alertModal.error .singIn.errorIsUser .jumpPage {
  font-size: 20px;
}

.OrdersTopCards {
  width: 100%;
  min-height: 109px;
  margin: 0 0 30px 0;
}

.OrdersTopCards ul li {
  background: white;
  display: inline-block;
  width: 321px;
  height: 100px;
  margin: 0 0 0 18px;
  overflow: hidden;

  border-radius: 10px;
  position: relative;

  box-shadow: 0px 2px 5px 1px rgb(0 0 0 / 17%);

  padding: 15px 0px 0 0;
}

.OrdersTopCards ul li::before {
  content: "";
  width: 7px;
  background: black;
  position: absolute;

  top: 0;
  bottom: 0;
}

.OrdersTopCards ul li:nth-child(1)::before {
  background: #1caa84;
}
.OrdersTopCards ul li:nth-child(2)::before {
  background: #f4bd0e;
}
.OrdersTopCards ul li:nth-child(3)::before {
  background: #f72b50;
}

.OrdersTopCards ul li.active:nth-child(1) {
  background: #1eaa8430;
}
.OrdersTopCards ul li.active:nth-child(2) {
  background: #f4bd1033;
}
.OrdersTopCards ul li.active:nth-child(3) {
  background: #f6c5c57a;
}

.OrdersTopCards ul li:last-child {
  margin-left: 0;
}

.OrdersTopCards ul li .icon {
  display: inline-block;
  vertical-align: top;
  padding: 0 25px;
}

.OrdersTopCards ul li .text {
  display: inline-block;
  vertical-align: top;
}

.OrdersTopCards ul li .text h3 {
  font-size: 16px;
  line-height: 1;
  margin: 0 0 5px 0;
}

.OrdersTopCards ul li .text p {
  font-size: 25px;
  line-height: 1;
  margin: 0;
}

.OrdersTopCards ul li button {
  font-size: 13px;
  background: none;
  border: none;
  padding: 0;
  color: #0058ff;
}

.OrdersTopCards ul li button:hover,
.OrdersTopCards ul li button:focus {
  text-decoration: underline;
}

.OrdersSearch {
  margin: 0 0 20px 0;
}

.OrdersSearch h2 {
  font-size: 15px;
}

.OrdersSearch .inputs .inputGroup {
  width: 30%;
  vertical-align: middle;
  display: inline-block;
  margin: 0 0 0 1%;
}

.OrdersSearch .inputs .inputGroup:last-child {
  width: 38%;
  margin-left: 0;
}

.OrdersSearch .inputs .inputGroup .form-control {
  width: 100%;
}

.accordions .accordion-item,
.accordionSec h2.accordion-header button {
  border-radius: 10px 10px 0px 0px !important;
}

.accordionSec .accordion-button.collapsed {
  border-radius: 10px 10px 10px 10px !important;
  box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 15%);
}

.accordions .accordion-item {
  border-radius: 10px 10px 10px 10px !important;
}

.accordionSec h2.accordion-header button {
  font-size: 25px;
  background: white;
  color: $blackfontText;
  font-family: $fontBold;
}

.accordionSec h2.accordion-header button::after {
  left: 20px;
  position: absolute;
  background: url("../img/nathan/svg/arrowDown.svg") center center no-repeat;
  background-size: 100%;
  width: 25px;
  height: 25px;
}

.accordionSec h2.accordion-header button small {

  color: grey;
  font-family: $fontRegular;
  font-size: 18px;
  display: inline-block;
  margin: 3px 10px 0 0;

}

.accordionSec .accordion-body {
  padding: 0;
}

.accordionSec .accordion-body .acordCont {
  padding: 20px 40px;
}

.accordionSec .accordion-body .acordCont.colorDown {
  border-bottom: 3px solid #e0e3f1;
}

.accordionSec .accordion-body .acordCont.colorDown:last-child {
  border: none;
}

/* .accordionSec .accordion-body .acordCont.is_outdated {
    background: #f7f7f7;
} */

.accordionSec .accordion-body .acordCont.noPaddLR {
  padding-left: 0;
  padding-right: 0;
}

.accordionSec .accordion-body .acordCont.noPaddLR .addPadd {
  padding-left: 40px;
  padding-right: 40px;
}

.accordionSec .accordion-body .acordCont h2 {
  font-size: 20px;
  font-family: $fontBold;
  color: $primary-color;
  margin: 0 0 20px 0;
}

.orderDetails .input:first-child {
  width: 29%;
}

.acordCont .hospitalShiftsSelects {
  margin: 20px 0 0px 0;
}

.acordCont .emergencyService {
  margin: 20px 0 0 0;
}

.acordCont .checkboxes {
  margin: 20px 0 0 0;
}

.acordCont .checkboxes .checkBoxCont {
  display: inline-block;
  vertical-align: top;
  width: 85px;
}

.acordCont .contactSection .input:last-child {
  width: 56%;
}

.acordCont .patientData .input:first-child {
  width: 40%;
}

.sendNewOrder {
  margin: 40px 0 0 0;
  text-align: left;
}

.sendNewOrder .orderCopiesSelect {
  
  width: 160px;
  display: inline-block;
  margin: 0 0 0 10px;

  select {
    border-radius: 7px;
    padding-top: 8px !important;
  }
}

.accordions .accordion-item {
  margin: 0 0 20px 0;
}

.accordions .accordion-item header.isNewOrder h2 {
  margin: 0;
}

.accordions .accordion-item header.isNewOrder p {
  margin: 0 0 20px 0;
}

.editHazmanaHeader ul {
  margin: 0 20px 0 0;
}

.editHazmanaHeader ul li {
  font-family: $fontRegular;
  font-size: 15px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 15px;
}
.editHazmanaHeader ul li span {
  margin: 0 0 0 3px;
}

.editHazmanaHeader ul li span,
.editHazmanaHeader ul li strong {
  vertical-align: middle;
}

ul.showOutdated li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 20px;
}

ul.showOutdated li strong {
  /* font-weight: normal; */
  color: $primary-color;
  /* color: grey; */
}

ul.showOutdated li strong.numbers {
  font-size: 16px;
}

.WorkerLinkSearch {
  background: #f4f7f8;
  margin: 20px 0;
  padding: 20px 40px;
}

.WorkerLinkSearch h3 {
  font-size: 15px;
  color: $primary-color;
}

.WorkerLinkSearch .inputs .input-group {
  display: inline-block;
  vertical-align: middle;
  width: 29%;
  margin: 0 0 0 1%;
}

.WorkerLinkSearch .inputs .input-group:last-child {
  width: 40%;
  margin-left: 0;
}

.WorkerLinkSearch .inputs .input-group .form-control {
  width: 100%;
}

.defaultTable.workersTable .tableTR .tableTD:nth-child(1) {
  width: 7%;
}
.defaultTable.workersTable .tableTR .tableTD:nth-child(2) {
  width: 10%;
}
.defaultTable.workersTable .tableTR .tableTD:nth-child(3) {
  width: 10%;
}
.defaultTable.workersTable .tableTR .tableTD:nth-child(4) {
  width: 20%;
}
.defaultTable.workersTable .tableTR .tableTD:nth-child(5) {
  width: 10%;
}
.defaultTable.workersTable .tableTR .tableTD:nth-child(6) {
  width: 42%;
}

.showselectedNurse {
  /* background: #D8DFE6; */
  margin: 20px 0;
  padding: 20px 40px;

  border-top: 1px solid #c6c7cd;
  border-bottom: 1px solid #c6c7cd;
}

.showselectedNurse ul li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 20px;
}

.showselectedNurse ul li .btnChange {
  background: #e0e5eb;
  border: 1px solid #aebdcc;
  padding: 1px 20px;
  border-radius: 5px;
}

.showselectedNurse ul li .btnChange img {
  margin: 0 0 0 10px;
}

.showselectedNurse ul li .btnChange:hover,
.showselectedNurse ul li .btnChange:focus {
  background: #ebe3fb;
}

.WorkersPage .WorkerLinkSearch {
  background: white;
  box-shadow: 0px 2px 5px 1px rgb(0 0 0 / 17%);
}

.headerWorkers h1 {
  width: 60%;
  display: inline-block;
}

.headerWorkers a,
.headerWorkers button
{
  float: left;
}

.NurseDataCont {
  
  padding: 20px 40px;
  box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 15%);

  .datesCont {

    display: flex;
    gap: 30px;
    margin: 10px 0 0 0;

    .dateItem {

      display: flex;
      align-items: center;
      gap: 10px;

      h4 {
        margin: 0;
        font-size: 20px;
      }

      button {
        background: none;
        border: none;
        padding: 0;
        color: red;
        font-size: 14px;

        &:hover {
          color: black;
        }
      }

    }

    span {
      color: $primary-color;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
    


  }
  

  
}

.NurseDataCont h2 {
  font-size: 25px;
  background: white;
  color: $blackfontText;
  font-family: $fontBold;
  margin: 0 0 20px 0;
}

.WorkerEditPage .inputs .input {
  display: inline-block;
  vertical-align: top;
  margin: 0 0 0 1%;
  width: 20%;
}

.WorkerEditPage .inputs .input:last-child {
  margin-left: 0;
}

.WorkerEditPage .inputs .section1 .input:nth-child(4) {
  width: 20%;
}

.WorkerEditPage .inputs .section1 .input:last-child {
  width: 15%;
}

.WorkerEditPage .inputTypeselect {
  vertical-align: top;
}

.WorkerEditPage .customSelectInput {
  width: 100%;
  padding: 18px 10px 8px 37px !important;
  border-radius: 10px;
  border: 1px solid #dee5ec;
}

.NurseDataCont h3 {
  font-size: 20px;
  font-family: $fontBold;
  color: $primary-color;
  margin: 0 0 20px 0;
}

.NurseDataCont h3 div {
  display: inline-block;
    margin: 0px 0 20px 0;
    font-size: 15px;
    color: $grey;
    font-family: $fontRegular;
    font-weight: normal;
}

.WorkerEditPage .inputs .section2 .input:nth-child(1) {
  width: 100%;
}

.WorkerEditPage .inputs .section2.widthPass .input:nth-child(1) {
  width: 70%;
}

.WorkerEditPage .inputs .section2.widthPass .input:nth-child(2) {
  width: 28%;
}

.inputs .section2.widthPass .input:nth-child(2) input {
  background: #fdf6ff57;
}



.WorkerEditPage .inputs .section3 .input {
  margin: 10px 0 0 0;
  width: 100%;
}



.WorkerEditPage .inputs .section4 .input {
  width: 100%;
}

.WorkerEditPage .inputs .line.checkboxes {
  margin: 20px 0 0 0;
  
}

.WorkerEditPage .downBtn {
  padding: 20px 0 0 0;
  text-align: left;
}


.WorkerEditPage .accordionSec  {
  
  
  .sendSmsBtn {
    float: left;
    font-size: 16px;
    padding: 0;
    border: none;
    background: none;
    color: $primary-color;
    margin: -10px 0 0 0;

    &:hover {
      color: $primary-color-hover;
      text-decoration: underline;
    }
  }

}

.WorkerWeeklyScheduleCont {

    margin: 30px 0 0 0;

}

.WorkerWeeklySchedule {

    &__saveData {
      text-align: left;
    }

    &__buttonsCont {

      ul {
        width: 100%;
        margin: 0 0 10px 0;
      }

      li {

        color: $blueButtonsTextColor;
        display: inline-block;
        min-width: 110px;
        
        &.dayName {
          border: 1px solid #DEE5EC;
          padding: 10px 10px;
          font-size: 14px;
          border-radius: 5px;
          text-align: center;
          margin: 0 0 0 10px;
          cursor: pointer;
        }

        &.dayName:hover
          {
            background-color: $primary-color-hover;
            color: $blueButtonsTextColorHover
          }
       
        button {

          background-color: $blueButtonsBgColor;
          border: none;
          width: 100%;
          border: 1px solid #DEE5EC;
          border-left: none;
          padding: 10px 10px;
          font-family: $fontRegular;
          font-size: 14px;

          

          &:hover
          {
            background-color: $primary-color-hover;
            color: $blueButtonsTextColorHover
          }


        }

        &:last-child button {
          border-left: 1px solid #DEE5EC;
          border-radius: 5px 0 0 5px;
        }

        &:nth-child(2) button {
          border-radius: 0px 5px 5px 0;
        }
        
  
        &.active button,
        &.active.dayName
        {
          background-color: $blueButtonsBgColorActive;
          color: $blueButtonsTextColorHover
          
        }

        


      }

      
      
    }

}

.showWorkerShifts {

  padding: 20px 0;

  h3 {

    text-align: center;
    font-size: 20px;
    color: red;

  }

  .FirstNurseOrders {

    header {

      h2 {

        font-size: 25px;

      }

    }

  }

}

.accordeonContent {

  padding: 20px 40px;
  box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 15%);
  
}

.SystemConfigIndex {


  /* .shortContent {
    width: 800px;

  } */

  .SystemHospitals {

    .buttonCont {
      float: left;

      button {

        padding: 2px 15px;

        span {
          font-size: 16px;
        }

        img {
          width: 16px;
        }
        
      }

    }

    

    .addHospitaCont {
      margin: 60px 0 20px 0;
      

      form {

        .form-control {
          display: inline-block;
          width: 35%;
          vertical-align: top;
        }

        input {
          padding: 9px 10px;
          margin: 0 0 0 2%;
        }
        

        select.customSelectInput {
          width: 30%;
          padding: 9px 10px 9px 37px !important;
        }

        button.defaultBtnPic {

          display: inline-block;
          width: 30%;
          padding: 8px 10px;
          vertical-align: top;
          margin: 0 2% 0 0;

        }


      }

    }

    &.SystemContracts .addHospitaCont {

      form {

        .form-control {
          display: inline-block;
          width: 30%;
          vertical-align: top;
          margin: 0 0 20px 1%;
        }

        text-align: center;

      }

      border-bottom: 1px solid #d1d1d1;
      padding-bottom: 30px;
      
    }



  }



  .tableHospital {

      td, th {
          color: $blackfontText;
          text-align: right;
          font-size: 15px;
          vertical-align: middle;
      }

      th {
          font-family: $fontBold;
          font-weight: normal;
          border: 2px solid white;

      }

      td {
        
        &:last-child {
          text-align: left;
          padding-left: 40px;
        }
        
      }

      td.noResults {
          color: red;
      }


      button.editBtnHospital {
        background: none;
        border: none;
        padding: 0;
        margin: 0 10px 0 0;
      }

  }

  .SystemEditHospital {
    
    .goBackBtnCont {
      text-align: left;
      margin: 0 0 -10px 0;
      float: left;
    }

    header.hospitalName {

      h3 {
        display: inline-block;
      }

      p {
        margin: 0 10px 0 0;
        display: inline-block;
      }

    }

    .sendBtnCont {
      text-align: left;

      .sendBtn {
        background: $primary-color;
        color: white;
        border: none;
        padding: 5px 40px;
        border-radius: 5px;

        &:hover {
          background-color: $primary-color-hover;
        }
      }

    }

   


    .hospitalDataDays {
      margin: 20px 0 0 0;

      .groupDays {

        margin: 0 0 40px 0;

        h4 {
          font-family: $fontRegular;
          font-weight: normal;
          font-size: 17px;
          margin: 0 0 20px 0;
        }

        ul li {

          font-size: 16px;
          display: inline-block;
          vertical-align: middle;
          width: 80%;
          padding: 5px 0;

          &.hospitalShifts {
            width: 20%;
            
          }

          .hospitalEditInputs {

            

            .form-floating {



              width: 32%;
              display: inline-block;
              vertical-align: middle;
              margin: 0 0 0 2%;

              &:last-child {
                margin-left: 0;
              }

              &.empty input {
                border-color: $lightRed;
                color: rgb(189, 189, 189);
              }

              .form-control {
                border-color: $boderLi;
                font-size: 16px;
              }

              
              > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
                  font-size: 16px;
              }
            }
          }


        }

        

      }

    }



  }

}


.OrderPayIndex {


  .reloadData {

    padding: 5px 15px;
    
    button {

      &:hover {
        color: $primary-color-hover;
      }

    }

  }


  .addPad {
    padding: 20px 40px;
  }

  .OrderPayShowTotals {

    background-color: $grey-light;
    padding: 10px 40px 10px 10px;
    margin: 0 0 0px 0;

    h2 {
      margin-bottom: 0px !important;
    }

    .line {
      margin: 0 0 10px 0;
    }

    ul li {
      font-size: 17px;
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 0 5px;
      border-left: 1px solid grey;
      padding: 0 0 0 5px;

      small {
        margin: 0 5px 0 0;
      }

      &:last-child {
        border-left: none;
      }

      &.price {
        color: $primary-color-hover
      }

    }



  }

  .buttonsCont {
    padding: 20px 0;
    margin: 20px 0 0 0;
    text-align: center;
  }

  .buttonsCont button {
      margin: 0 10px;
  }


  .OrderPayCreditCardForm {

    .input {
      
      width: 10%;
      display: inline-block;
      vertical-align: top;
      margin: 0 0 0 1%;
      
      &.inputDates {
        width: 13%;
      }

      &.cardNumber {
        width: 25%;
      }

      &.TZ {
        width: 20%;
      }

      &.total {
        width: 20%;

        .customFloatInput {
          border-color: $primary-color;
          color: $primary-color;
          font-family: $fontBold;
        }

        
      }

      .customFloatInput {
        font-size: 16px;
      }

      

    }


    .centerTotal {
      
      margin: 10px 0 0 0;
      text-align: center;

      .defaultBtnPic {

        padding: 10px 30px;

        &.disabled {
          opacity: .5;
        }

      }

    }

  }

  .OrderPayAddPayItems {

    .OrderPayAddPayItemsShow {
      margin: 20px 0 0 0;

      ul li {

        strong, span {
          display: inline-block;
          vertical-align: middle;
        }

        &:first-child strong {
          min-width: 245px;
        }

        strong {
          min-width: 250px;
          padding: 0 0 0 10px;
        }

        span {

          small {
            margin: 0 5px 0 0;
          }

          
          direction: ltr;
          text-align: left;
          
        }

        button {
          margin: 0 0px 0 10px;
          color: $primary-color;
          font-size: 15px;
          background: none;
          padding: 0;
          border: none;

          &:hover {
            color: $primary-color-hover;
            font-size: 18px;
          }

        }


      }

    }

    h2 {
      margin-bottom: 10px !important;
    }

    .inputs {

      .input {

        .form-floating > .form-control:focus ~ label,
        .form-floating > .form-control:not(:placeholder-shown) ~ label,
        .form-floating > .form-select ~ label {

          margin-right: -5px;
          
        }

        vertical-align: top;
        display: inline-block;
        margin: 0 0 0 1%;
        width: 25%;

        label {
          padding-right: 0;
        }

        &.price {
          width: 15%;
        }

        .form-control {
          border: none;
          border-bottom: 1px solid #dee5ec;
          border-radius: 0;
          padding-right: 0;
        }

        .form-control:disabled {
          background: none;
          color: $primary-color;
          font-weight: bold;
        }
        
      }


      .defaultBtnPic {

        margin: 10px 5px 0 0;
        display: inline-block;

        

        &.disabled {
          opacity: .5;
        }

      }

    }

  }



}

.OrderInvoiceIndex {

  padding: 50px 0 0;
  min-height: 200px;
  
  a {

    margin: 0 280px 0 0;

    img {
      width: 200px;
    }

    &:hover img {
      width: 230px;
    }

  }

  background: url('../img/nathan/pdfDownFile/bgPdfDown.png') left bottom no-repeat;


  .OrderInvoiceForm {

    .inputs {
      padding: 0 0 0 30%;

      .input {
        width: 48%;
        margin: 0 0 10px 1%;
        display: inline-block;
        vertical-align: top;

        &.adress {
          width: 97%;
        }
      }

      .btnCont {
        text-align: left;
        padding: 10px 0 0 3%;

      }

    }

  }

}

.SystemHospitalsUsers {

  h2 {
    font-size: 20px;
    font-family: "fb_bold", "helvetica", "arial";
    color: #562669;
    margin: 0 0 20px 0px;
  }

  .inputs .input {
    width: 48%;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 10px 2%;
  }

  .btnCont {
    text-align: left;
    padding: 0 0 0 10px;
    margin: 0 0 20px 0;
  }

  .SystemHospitalsShowUsers {

    margin: 30px 0 0 0;

    .tableHospital {

        text-align: center;

        td,th {
          text-align: center;
        }

        td {

          &:last-child {
            padding: 0;
          }

          button {

            &.changePass {
              background: #56266940;
              /* width: 100%; */
              
              margin: 5px 0 0 0;
              border-radius: 5px;
              padding: 2px 10px;
              border: none;

              &:hover {
                background: $primary-color;
                color: white;
              }

            }

            color: $primary-color;

            &:hover {
              color: $primary-color-hover;
            }

          }

        }
      
        td.noResults {

          text-align: center;

        }
    }
    
  }

}


.OrderSms {
    display: inline-block;

    .inputsCont {
      display: inline-block;
      width: 480px;

      .inputGroup {

        width: 30%;
        display: inline-block;

        &:first-child {
          width: 40%;
        }

        input {
          width: 100%;
        }

        margin: 0 0 0 2%;
      }

    }
}


.timeModal {

  h2 {
    text-align: center;
    margin: 0 0 20px 0;
    font-size: 25px;
  }

  .timeInputs {

    padding: 0;
    text-align: center;

    .input {

      label {
        text-align: right;
        width: 100%;
      }

      input {
        
        font-size: 15px;

      }
      

      width: 48%;
      margin: 0 0 0 1%;
      display: inline-block;

      &:last-child {
        margin-left: 0;
      }


    }


  }

  .btnCont {

    margin: 40px 0 0 0;
    text-align: center;

    button {

      margin: 0 5px;
      padding: 5px 30px;

    }

  }

}

.WorkerContracts {

  padding: 0px 40px;
  

  .showContracts {

    h3 {
      color : $primary-color;
      text-align: right;
      margin: 20px 0 10px 0;
    }
    

    ul {

      li {

        margin: 0 0 5px 0;

        span.contractName {
          min-width: 320px;
          display: inline-block;
          /* border-bottom: 1px solid grey; */
          background: #f8f8f8;
          padding: 2px 5px;
          margin: 0 0 0 5px;
        }

        button {
          border: none;
          background: none;
          color: $primary-color;
          font-size: 17px;

          &:hover {
            font-size: 18px;
            color: $primary-color-hover;
          }


        }

      }

    }
    

  }

  .addContractCont {


    select {
      padding: 10px 20px 10px 37px !important;
      width: 60%;
      display: inline-block;
    }

    button {
      display: inline-block;
      margin: 0 10px 0 0;
      padding: 8px 20px;
    }

  }

  

}


.WorkerPaysIndex {

  padding: 20px 1.25rem;

  .nursePaysResultCont {

    .errorMessage {
      color: red;
    }

    ul li {

      font-size: 16px;

      &.contractName {

        span {
          text-decoration: underline;
        }
        padding-bottom: 5px;
        
      }

      &.totalAmount {
        font-size: 20px;
        padding: 10px 0 0 0;
        color: $primary-color;
      }

    }
    

  }

  .OrderPayAddPayItems {

    border-bottom: 1px solid rgb(173, 173, 173);

    padding: 0px 0 20px;
    margin: 0px 0 20px 0;
  }

}


.navMonewReport {

  padding: 20px 0 0 0;

}


.addSymbolWorker {

  margin: 20px 0 0 0;

  h2 {
    font-size: 20px;
    font-family: "fb_bold", "helvetica", "arial";
    color: $primary-color;
    margin: 0 0 10px 0px;
  }

  ul 

    {

      margin: 0 0 30px 0;

      li {

      strong, span {
        display: inline-block;
        vertical-align: middle;
      }

      &:first-child strong {
        min-width: 245px;
      }

      strong {
        min-width: 250px;
        padding: 0 0 0 10px;
      }

      span {

        small {
          margin: 0 5px 0 0;
        }

        
        direction: ltr;
        text-align: left;
        
      }

      button {
        margin: 0 0px 0 10px;
        color: $primary-color;
        font-size: 15px;
        background: none;
        padding: 0;
        border: none;

        &:hover {
          color: $primary-color-hover;
          font-size: 18px;
        }

      }


    }
  }

  .inputs  {

    margin: 0 0 30px 0;

    .selectLabel {

      label {

        right: 10px !important;

      }

    }

    button {

      padding: 9px 35px;
      

    }

  }
  
  

}

.hospitalHospitalList {

    padding: 10px 0 0 0;

    h4 {
      font-size: 16px;
    }

    ul.showHospitals {

      margin: 10px 0 0 0;

      li {
        margin: 0 0 5px 0;
      }

    }

    .deleteBtn {
      color: $primary-color;
      font-size: 18px;
      background: none;
      border: none;

      &:hover {
        color: $primary-color-hover;
      }

    }



}

.notesList {

  .form-floating {
    width: 80%;
    display: inline-block;
  }

  button {
    display: inline-block;
    margin-right: 1%;
    vertical-align: top;
  }

  

  padding: 10px 0 0 0;

 

  
  .notesListExist {

    margin: 30px 0 0 0;

    h4 {
      clear: both;
      font-size: 20px;
      margin-top: 10px 0 0 0;
    }

    ul {

      clear: both;

      margin: 10px 0 0 0;

      li {


        margin: 0 0 5px 0;
        font-size: 16px;

        strong {
          min-width: 135px;
          display: inline-block;
        }

        small {
          margin: 0 20px 0 0;
        }
        

        .content {
          margin-right: 10px;
        }

      }

    }

  }

}

.smsMessage {

  background: #f4f7f8;
  padding: 10px;

}

.retroOrder {

  display: flex;
  align-items: center;
  justify-items: center;
  margin: 20px 0 0 0;
  width: 40%;

  h3 {
    font-size: 16px;
    min-width: 120px;
    margin: 0;
    letter-spacing: -1px;
  }

  .react-datepicker-wrapper {
    width: 100px;
    text-align: center;
  }


  .cancelBtn {
    padding: 0;
    border: none;
    margin: 0 10px 0 0;
    background: transparent;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }



}


.nursePayPrati {
  
  padding: 0 20px;
  border-bottom: 1px solid rgb(228, 228, 228);
  
}

.unificateOrders {

  padding: 0 0px 30px 0;
  border-bottom: 1px solid rgb(228, 228, 228);

  ul {

    li {

      &.uniquePrati {
        color: grey;
        text-decoration: line-through;
      }

      margin: 0 0 10px 0;

      button {
        margin: 0 0 0 15px;
        background: rgb(187, 187, 187);
        padding: 2px 5px;
        border: none;
      }

      strong {
        font-size: 17px;
      }

    }

  }

  .sendSms {
    display: flex;
    gap: 10px;
    margin: 20px 0 0 0;

    button.disabled {
      opacity: 0.5;
    }


  }

}

.periodReport {

  margin: 30px 0 0 0;
  border-top: 1px solid grey;
  padding: 20px 0 0 0;

  h3 {

    font-size: 20px;
    margin: 0 0 -5px 0;

  }

  h4 {
    text-align: center;
    font-size: 14px;
    margin: 0;
  }

  .customSelect {
    margin: 0;
  }

  .row {
    /* justify-content: flex-end; */
    align-items: flex-end;
  }

  input {
    padding: 9px 5px;
  }

}

.showHoursHospitalUser {

  margin: 20px 0 0 0;

  h3 {
    font-size: 20px;
    color: #562669;
    margin: 0 0 5px 0;
  }

  span {
    margin: 0 0 0 20px;
  }

}

.holydayShitch {
  
  margin: 0 10px 0 0;
  cursor: pointer;

  label {
    font-size: 16px;
  }

  .form-check-input:checked {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  &.active {
    label {
      color: $primary-color;
      font-family: $fontBold;
      font-size: 18px;
    }
  }

}

.cancelOrderDropdown {

  display: inline-block;
  margin: 0 11px;

  .customSelectInput {
    
    font-size: 19px;
    background: #d8dfe6;
    color: black;
    border-radius: 7px;
    /* text-align: center; */
    padding: 3px 20px !important;
    box-shadow: 0px 2px 5px 1px rgb(0 0 0 / 17%);

  }
}

.insertFiles {

  margin: 0 0 40px 0;

  .user-file {
      width: 100%;
      margin: 0px auto 00px;

      cursor: pointer;

      img {


          &.noPic {
              opacity: .2;
              border: 2px dashed grey;
              border-radius: 10px;
              padding: 10px;

              &.errorNoFile {
                border-color: red;
                opacity: 1;
              }
          }

          &:hover {
              opacity: 1;
          }

      }

  }

}

.filesWorkerIndex {

    .showFiles {

    /* tr {
      td {
        cursor: pointer;
      }
    } */

    .defaultTable tr td .openWindowBtn.editBtn {

      margin: 0 0 0 5px;
      width: 30px;
      vertical-align: middle;

      svg {
        color: grey;
        font-size: 24px;
        

        &:hover {
          color: black;
          font-size: 26px;
        }

      }

      

    } 


    .showPic {
      width: 50px;
      height: 50px;
      background: center center no-repeat white;
      background-size: 80%;

      margin: 0 auto;
    }

  }

  .showAllFiles {

    margin: 20px 0 0 0;

    ul {

      li {

        display: flex;
        gap: 15px;
        align-items: center;
        margin: 0 0 5px 0;

        a {

          text-decoration: none;

          color: $blueButtonsBgColorActive;

          &:hover {

            color: $primary-color-hover;
            text-decoration: underline;

          }


        }

        .statusOff {

          cursor: pointer;
          font-size: 14px;

          &:hover {

            font-size: 16px;
            color: $primary-color-hover;

          }

        }

      }

    }


  }
}

.bonusWorker {

  width: 210px;
  margin: 0 30px 20px 0;
  cursor: pointer;

  label {
    color: $primary-color;
    font-weight: bold;
    cursor: pointer;
  }

}
