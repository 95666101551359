

$blackfontText: #1F2631;

$primary-color: #562669;
$primary-color-hover: #8540a1;

$seccond-color: #f8b12a;
$redDanger: red;

$lightRed: #fb7272;

$blueButtonsBgColor: white;
$blueButtonsBgColorActive:#0058FF;
$blueButtonsTextColor: black;


$blueButtonsColorHover:white;
$blueButtonsTextColorHover:white;

$shadowDefault: -1px 0px 5px 1px rgba(58, 86, 122, 0.16);

$grey: #4B5057;
$grey-light: #f1f1f1;
$boderLi: #e6e6e6;

