@import '../../../css/scss/index.scss';

.NurseEnv {

    .errorH2Panel {
        text-align: center;
        margin: 0;
        font-size: 20px;
        color: red;
    }

    /* general START */
    .shadowDefault {
        -webkit-box-shadow: $shadowDefault;
        -moz-box-shadow: $shadowDefault;
        box-shadow: $shadowDefault;
    }

    .whitePanel {
        width: 95%;
        margin: 0 auto 5px;
        padding: 20px 20px;
        background: white;
        position: relative;
        border-radius: 10px;

        &.noWithPadd {
            padding: 20px 0;
        }
    }

    /* END general START */


    .FirstScreenUserData {

        .userData {

            .reloadBtn {

                display: inline-block;
                vertical-align: middle;
                padding: 0 0 0 10px;

            }

            .text {

                display: inline-block;
                vertical-align: middle;

                h2 {
                    font-family: $fontBold;
                    margin: 0;
                    line-height: 1;
                }
                p {
                    margin: 0;
                    font-size: 15px;
                    color: $grey;
                }

            }

        }

        

        .toConfig {
            color: #0058FF;
            text-decoration: none;
            position: absolute;
            top: 10px;
            left: 10px;

            span {
                font-size: 16px;
                margin-right: 2px;
            }
        }
    }


    .FirstScreenMainOrder {

        .infoHeader {

            h2 {
                font-family: $fontBold;
                text-align: center;
            }

            ul {

                text-align: center;

                li {
                    
                    display: inline-block;
                    color: $blueButtonsBgColorActive;
                    margin: 0 5px;

                    &.emergencyLine {
                        color: black;
                    }
                }

            }

        }

        ul.hospitalData {
            text-align: center;

            li {
                display: inline-block;
                margin: 0;
                color: $grey;
                padding: 0 10px;
                border-left: 1px solid $boderLi;

                &:first-child {
                    padding-right: 0;
                }

                &:last-child {
                    padding-left: 0;
                    border-left: none;
                }

            }
            
        }

        .FirstStartShift {
            margin: 20px 0 0 0;
        }
        


    }

    .FirstStartShift {


        .FirstStartShiftCont {

            margin: 10px auto 0;
            text-align: center;
            padding: 0 0 0px 0;

            .confirmBtn {
                margin: 0px 0 20px 0;
            }

            h3.orderConfirmOk {
                color: green;
            }

            h3.orderConfirmNO {
                color: red;
            }

            .showStartStopCont {

                &.disabled {
                    opacity: 0.5;
                }

                h3 {
                    font-family: $fontBold;
                    text-align: center;
                    font-size: 14px;
                }
        
                .showTime {
        
                    text-align: center;
                    font-size: 16px;
        
                    li {
                        display: inline-block;
        
                        &:first-child {
                            margin-left: 40px;
                        }
                    }
        
        
                }
    
                .playStop {
                    background: none;
                    text-decoration: none;
                    padding: 10px 0;
                    margin: 0;
                    border: none;
    
                    &:disabled {
                        opacity: 0.5;
                    }
                }


            }
            

            .downCont {

                width: 70%;
                border-top: 1px solid $boderLi;
                margin: 10px auto 0;
                padding: 10px 0 0 0;

                ul {

                    text-align: center;

                    li {
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0 5px;

                        &.number {
                            font-size: 40px;
                        }
                    }
                }

            }

        }

    }

    .FirstUpdataEmergency {
        text-align: center;
    }

    
    .ConfigIndex {

        margin: 20px 0 0 0;

        h1 {
            font-size: 25px;
            text-align: center;
            margin: 0 0 10px 0;
        }

        .userConfDataCont {
            position: relative;
            padding: 10px 20px;
        }

        button.editMode {
            border: none;
            position: absolute;
            top: 10px;
            left: 10px;
            background: transparent;
            color: $blueButtonsBgColorActive;
            font-size: 16px;            
        }

        .userData {

            font-size: 17px;

            .nameHeader {

                h2 {
                    margin: 0;
                    font-family: $fontBold;
                    font-size: 20px;
                }

                p {
                    font-size: 15px;
                }
            }

            ul {

                &.moreData {
                    margin: 20px 0 0 0;
                    border-top: 1px solid $boderLi;
                    padding: 15px 0 0 0;

                    li strong {
                        margin-right: 0;
                    }
                }
            
                li {
                
                    margin: 0 0 10px 0;

                    img {
                        vertical-align: middle;
                    }

                    strong {
                        margin: 0 10px 0 0;
                        vertical-align: middle;
                    }

                    span {
                        vertical-align: middle;
                    }

                    h3 {
                        padding: 20px 0 0 0;
                        color: $primary-color;
                        font-family: $fontBold;
                        font-size: 20px;
                    }
                
                }
            }

        }


        .NurseDataCont {
            box-shadow: none;
            padding: 0;

          
            .inputs .input {

                margin: 0 0 10px 0;

            }

            .downBtn {
                text-align: center;
                padding: 10px 0;
            }
        }        


        .accordionSec {

            width: 95%;
            margin: 10px auto 0;

            .accordions .accordion-item {
                border: none;
            }

            h2 button {
                
                color: $primary-color;
                font-family: $fontBold;
                font-size: 20px;
            }

            .NurseDataCont {

                padding: 10px 20px;

                ul li {

                    &.btnCont {
                        margin: 0;
                        padding: 0;
                    }

                    &.dayName {
                        min-width: 60px;
                    }
                

                    padding: 5px 10px;
                    min-width: inherit;
                    margin-left: 5px;

                    &.active button {
                        background-color: $blueButtonsBgColorActive !important;
                        color: white !important;
                    }

                    button {
                        padding: 5px 10px;

                        background: white !important;
                        color: black !important;

                    }
                }

                .WorkerWeeklySchedule__saveData {
                    margin: 30px 0 10px 0;

                    button {
                        font-size: 16px;
                    }
                }

            }

        }


    }

    
    .ConfirmPage {


        h2 {
            text-align: center;
            font-size: 25px;
            color: $primary-color;
            margin: 0;
        }

        h1 {
            text-align: center;
            font-size: 30px;
            color: $primary-color;

            span {
                font-size: 25px;
            }
        }

        .BtnCont {
            margin: 20px 0;

            button {
                width: 100%;
                box-shadow: $shadowDefault;
                margin: 0 0 10px 0;
            }
        }

        .answer {
            text-align: center;
            padding: 20px;

            > div {
                
                padding: 10px;
                border-radius: 10px;
            }

            .ok {
                color: green;
            }

            .error {
                color: red;
                
            }

        }

        .errorDiv {

            padding: 20px 0;

        }


    }



}


.Mobile .NurseDataCont h3 div.username {
    display: block;
}


.FirstNurseOrders {

    .NurseOrdersSearch {


        .filterCont {

            h2 {
                margin: 0 0 20px 0;
            }

            form {

                select {
                    width: 39%;
                    display: inline-block;
                    padding: 9px 10px 8px 37px !important;
                    margin: 0 0 0px 10px;
                }

            }

        }

    }



    header {
        font-family: $fontBold;
        text-align: center;
        font-size: 20px;
        line-height: 1;
        margin: 0 0 20px 0;
        position: relative;

        button.showArchiveChangeBtn {
            position: absolute;
            top: 0;
            left: 10px;
            background: none;
            padding: 0;
            border: none;

            span {
                font-family: $fontRegular;
                font-size: 15px;
                color: $blueButtonsBgColorActive;
                margin: 0 5px 0 0;
            }
        }

        .goBackBtnCont {
            text-align: right;
            padding-right: 10px;
            font-family: $fontRegular;
            font-size: 15px;
            margin: 0;
        }
    }

    p {
        text-align: center;
        margin: 0;
        font-size: 17px;
        color: $grey;
        font-family: $fontRegular
    }

}

.tableNurseOrders {

    td, th {
        color: $blackfontText;
        text-align: center;
        font-size: 14px;
    }

    th {
        font-family: $fontBold;
        font-weight: normal;
        border: 2px solid white;
        
    }

    td.noResults {
        color: red;
    }



    

}



